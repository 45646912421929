import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Img1 from "../../../../assets/svg/version1.svg";
import Img2 from "../../../../assets/svg/version2.svg";

type VersionSelectProps = {
  setVersionView: Dispatch<SetStateAction<string>>;
};

const VersionSelect: React.FC<VersionSelectProps> = ({ setVersionView }) => {
  return (
    <Container>
      <h2>Version Control Hub</h2>
      <div className="box-container">
        <div className="box" onClick={() => setVersionView("downgrade")}>
          <img src={Img1} alt="" />
          <div className="box-text">
            <h3>Manage version relaeses</h3>
            <p>
              Roll back to a previous version if you encounter any issues with
              the current release.
            </p>
          </div>
        </div>
        {/* <div className="box" onClick={() => setVersionView("manage")}>
          <img src={Img2} alt="" className="imgfolder" />
          <div className="box-text">
            <h3>Version Manager</h3>
            <p>
              Set which app version customers can use, and who needs to update.
            </p>
          </div>
        </div> */}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  h2 {
    color: #160D05;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }

  .box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    padding-bottom: 40px;
  }
  .box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid #160D05;
    color: #160D05;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }

    .imgfolder {
      width: 20px;
      height: 20px;
    }
    .box-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .box-text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
          font-size: 15px;
          font-weight: 600
        }

        p {
            font-size: 13px;
        }
    }
  }

  }
`;

export default VersionSelect;
