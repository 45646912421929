import { createContext, useContext, useReducer, useState } from "react";

type AppContextProviderProps = {
  children: React.ReactNode;
};
type AuthUser = {
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  location: string;
  region: string;
};

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  loading: false,
  errorMessage: "",
  isLoading: false,
  isActionLoading: false,
};
export type StateType = {
  user: AuthUser | null;
  token: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  errorMessage: string;
  isLoading: boolean;
  isActionLoading: boolean;
};
type UserLoadingType = {
  type: "user-loading";
};
type SetUserType = {
  type: "set-user";
  payload: AuthUser;
};
type LoginErrorType = {
  type: "on-login-error";
  payload: string;
};
type ResetType = {
  type: "reset";
};
type LogoutType = {
  type: "logout";
};
type DataLoading = {
  type: "data-loading";
};
type DataLoaded = {
  type: "data-loaded";
};
type ActionLoading = {
  type: "action-loading";
};
type ActionLoaded = {
  type: "action-loaded";
};

type Actiontype =
  | SetUserType
  | UserLoadingType
  | LoginErrorType
  | ResetType
  | LogoutType
  | DataLoading
  | DataLoaded
  | ActionLoading
  | ActionLoaded;

type AppContextType = {
  state: StateType | null;
  dispatch: React.Dispatch<Actiontype>;
  orderCsvData: any;
  setOrderCsvData: any;
  orderStatus: string;
  setOrderStatus: React.Dispatch<string>;
};

const reducer = (state: StateType, action: Actiontype) => {
  switch (action.type) {
    case "user-loading":
      return {
        ...state,
        loading: true,
      };
    case "set-user":
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        token: localStorage.getItem("token"),
        user: action.payload,
      };
    case "on-login-error":
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorMessage: action.payload,
      };
    case "reset":
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorMessage: "",
      };
    case "logout":
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        errorMessage: "",
      };
    case "data-loading":
      return {
        ...state,
        isLoading: true,
      };
    case "data-loaded":
      return {
        ...state,
        isLoading: false,
      };
    case "action-loading":
      return {
        ...state,
        isActionLoading: true,
      };
    case "action-loaded":
      return {
        ...state,
        isActionLoading: false,
      };
    default:
      return state;
  }
};

export const useAppContext = () => {
  return useContext(AppContext);
};

const AppContext = createContext({} as AppContextType);

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [orderCsvData, setOrderCsvData] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");

  const contextValue = {
    state,
    dispatch,
    orderCsvData,
    setOrderCsvData,
    orderStatus,
    setOrderStatus,
  };
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
