import { Dispatch, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import styled from "styled-components";
import {
  EmptyList,
  TableSkeleton,
  Pagination,
  Table,
  Button,
  TableTab,
} from "components";
import ContentLayout from "../../../layouts/ContentLayout";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ActionModal } from "./ActionModal";
import { ViewDetailsModal } from "./ViewDetailsModal";
import { BackDrop } from "components/BackDrop";
import AppVersionService from "../../../services/AppVersionService";
import { formatArrayToString } from "utils/formatArrayToString";
import UploadImg from "../../../assets/svg/upload.svg";
import CreateAppVersion from "./createVersionForm/CreateAppVersion";
import backArrow from "../../../assets/svg/arrow-left.svg";
import VersionSelect from "./versionControlForm/VersionSelect";
import DowngradeForm from "./versionControlForm/DowngradeForm";
import ManagerForm from "./versionControlForm/ManagerForm";
import SuccessMessage from "./versionControlForm/SuccessMessage";

const appHeaders = [
  { key: 1, item: "App version" },
  { key: 2, item: "Release note" },
  { key: 3, item: "Depot" },
  { key: 4, item: "Start date" },
  { key: 5, item: "End date" },
  { key: 7, item: "Action" },
];

const navItems = [{ link: "Release Version", linkTo: "" }];

export type ItemType = {
  version: string;
  versionId: string;
  isEnabled: boolean;
  depots: [];
  regions: [];
  images: [];
  releaseNote: string;
  downloadUrl: string;
  _id: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  _v: string;
};

const convertToString = (itemKey: any) => {
  const convertedString = itemKey.join(" | ");

  return convertedString;
};

const AppDistribution = () => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const isLoading = state?.isLoading;
  const [isReset, setIsReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setSecondIsModalOpen] = useState(false);
  const [willFilter, setWillFilter] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [csvData, setCsvData] = useState();
  const [versionView, setVersionView] = useState("select");

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: { data, meta },
        } = await AppVersionService.getAllAppVersions(limit, page);

        const csvData = data.map((item: ItemType) => {
          return {
            id: item._id,
            app_version: item.version || item.versionId,
            release_note: item.releaseNote,
            depots: convertToString(item.depots),
            start_date: item.startDate,
            end_date: item.endDate,
            status: item.isEnabled ? "Active" : "Inactive",
            createdAt: item.createdAt,
          };
        });
        setCsvData(csvData);
        setTableData(data);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    fetchData();
  }, [limit, page, willFilter, dispatch]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsReset(false);
  };

  const handleCloseButton = () => {
    setIsReset(true);
    setIsModalOpen(false);
  };

  const handleSecondModalClose = () => {
    setSecondIsModalOpen(false);
    setVersionView("select");
  };

  const handleSecondCloseButton = () => {
    setIsReset(true);
    setSecondIsModalOpen(false);
  };

  return (
    <>
      <ContentLayout
        titleText="App Distribution"
        subText="Managing app release"
        component={
          <>
            <AddSurveyButton {...{ setIsModalOpen }} />
            <ViewControlButton {...{ setSecondIsModalOpen }} />
          </>
        }
      >
        <Back className="back">
          <Link to="../dashboard/settings">
            <img src={backArrow} alt="back-arrow" />
            <p>Back</p>
          </Link>
        </Back>
        <ContentWrapper>
          <BackDrop
            isOpen={isModalOpen}
            handleClose={handleModalClose}
            handleCloseButton={handleCloseButton}
          >
            <CreateAppVersion
              handleModalClose={handleModalClose}
              setWillFilter={setWillFilter}
            />
          </BackDrop>
          <BackDrop
            isOpen={isSecondModalOpen}
            handleClose={handleSecondCloseButton}
            handleCloseButton={handleSecondModalClose}
          >
            {versionView === "select" && (
              <VersionSelect setVersionView={setVersionView} />
            )}
            {versionView === "downgrade" && (
              <DowngradeForm
                handleSecondModalClose={handleSecondCloseButton}
                setVersionView={setVersionView}
              />
            )}
            {versionView === "manage" && (
              <ManagerForm
                handleSecondModalClose={handleSecondCloseButton}
                setVersionView={setVersionView}
              />
            )}
            {versionView === "successDowngrade" && (
              <SuccessMessage
                handleSecondModalClose={handleSecondModalClose}
                text="Version excluded successfully"
              />
            )}
            {versionView === "successManage" && (
              <SuccessMessage
                handleSecondModalClose={handleSecondModalClose}
                text="Version manager set-up successful"
              />
            )}
          </BackDrop>
          <ProductsTable>
            <TableTab
              canFilter={false}
              data={csvData}
              file="app-distribution.csv"
              navItems={navItems}
            />

            {isLoading ? (
              <TableSkeleton />
            ) : tableData.length === 0 ? (
              <EmptyList />
            ) : (
              <>
                <div className="table-container">
                  <Table
                    headers={appHeaders}
                    items={tableData}
                    renderItem={Distribute}
                  />
                </div>
                <Pagination
                  setLimit={setLimit}
                  limit={limit}
                  page={page}
                  pages={pages}
                  setPage={setPage}
                />
              </>
            )}
          </ProductsTable>
        </ContentWrapper>
      </ContentLayout>
    </>
  );
};

const AddSurveyButton = ({
  setIsModalOpen,
}: {
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
}) => (
  <ButtonContainer onClick={() => setIsModalOpen(true)}>
    <img src={UploadImg} alt="" />
    <span className="fs-100">Upload New APK</span>
  </ButtonContainer>
);
const ViewControlButton = ({
  setSecondIsModalOpen,
}: {
  setSecondIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
}) => (
  <ButtonContainer2 onClick={() => setSecondIsModalOpen(true)}>
    <img src={UploadImg} alt="" />
    <span className="fs-100">View Control Hub</span>
  </ButtonContainer2>
);

const Distribute = (item: ItemType) => {
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);
  let startDate = new Date(item.startDate);
  let endDate = new Date(item.endDate);

  return (
    <tr key={item._id}>
      <td onClick={() => setIsViewDetails(true)}>
        {item.version || item.versionId}
      </td>
      <td>{item.releaseNote || "Nil"}</td>
      <td>
        <div className="depot">
          {item?.depots && formatArrayToString(item.depots, "depot")}
          {item?.depots?.length > 2 && (
            <div className="depotDisplay">
              {item.depots.length > 1 &&
                item.depots.map((data, index) => {
                  return (
                    <span key={index}>
                      {data} {index !== item.depots.length - 1 && ", "}{" "}
                    </span>
                  );
                })}
            </div>
          )}
        </div>
      </td>
      <td>
        {item?.startDate ? (
          <p>{`${startDate.getDate()}/${
            startDate.getMonth() + 1
          }/${startDate.getFullYear()}`}</p>
        ) : (
          "Nil"
        )}
      </td>
      <td>
        {item?.endDate ? (
          <p>{`${endDate.getDate()}/${
            endDate.getMonth() + 1
          }/${endDate.getFullYear()}`}</p>
        ) : (
          "Nil"
        )}
      </td>
      <td onClick={() => setIsMoreAction(true)} className="pointer three-dots">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="24"
          viewBox="0 0 3 24"
          fill="none"
        >
          <path
            d="M1.50016 15.9965L1.50016 16.0054"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 11.9955L1.50016 12.0045"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 7.99451L1.50016 8.00349"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </td>

      <ActionModal
        item={item}
        display={isMoreAction}
        setIsModal={setIsMoreAction}
      />
      <ViewDetailsModal
        item={item}
        display={isViewDetails}
        setIsModal={setIsViewDetails}
      />
    </tr>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 3em;

  .chart-container {
    padding: 20px 0;
  }
`;
const ButtonContainer = styled.div`
  padding-left: 30px;
  display: flex;
  align-items: center;
  gap: 10px;

  background: #022f1a;
  color: #fff;
  padding: 15px 30px;

  border: none;
  border-radius: 5px;
  cursor: pointer;

  & span {
    font-size: 0.9rem;
  }
`;
const ButtonContainer2 = styled.div`
  padding-left: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #022f1a;
  background: #fff;
  color: #022f1a;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;

  & span {
    font-size: 0.9rem;
  }
`;

const ProductsTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }

  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
  .depot {
    position: relative;
  }
  .depotDisplay {
    position: absolute;
    background: #f0f0f0;
    width: 250px;
    border-radius: 6px;
    padding: 5px 0;
    visibility: hidden;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    word-wrap: break-word;
    justify-content: space-around;
    row-gap: 5px;
  }
  .depot:hover .depotDisplay {
    visibility: visible;
  }
`;

const Back = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1em 0 0 2em;
    color: #333;

    & p {
      font-weight: 600;
    }
  }
`;

export default AppDistribution;
