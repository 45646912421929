import Api from "./Api";

const Services = {
  addAppVersion(formData: any) {
    return Api().post("app-version", formData);
  },
  getAllAppVersions(limit: number, page: number) {
    var params = {
      limit,
      page,
    };

    return Api().get("all-app-versions", { params });
  },
  updateAppVersion(id: string, formData: any) {
    return Api().put(`update-version/${id}`, formData);
  },
  getMaxVersion() {
    return Api().get("appVersion/active");
  },
  dropdownGetAppVerisons() {
    return Api().get("app-versions");
  },
  configureAppVersion(formData: any) {
    return Api().post("app-config-manager", formData);
  },
  configureDowngardeVersion(formData: any) {
    return Api().post("app-config-downgrade", formData);
  },
  updateAppVersionStatus(formData: any) {
    return Api().post("app-version/status", formData);
  },
};

export default Services;
