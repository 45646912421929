import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Dropdown from "react-dropdown-select";
import styled from "styled-components";

interface MininumDropdownProps {
  setMinimumVersion?: any;
  setAppVersion?: any;
  versionDetails: [];
}

const MinimumDropdown: React.FC<MininumDropdownProps> = ({
  setMinimumVersion,
  versionDetails,
  setAppVersion,
}) => {
  // handle value change
  const handleDropdownChange = (values: any) => {
    if (setMinimumVersion) setMinimumVersion(values);
    if (setAppVersion) setAppVersion(values);
  };

  return (
    <Container>
      <div className="drop-down">
        <Dropdown
          options={versionDetails}
          labelField="version"
          valueField="_id"
          name="version"
          // onChange={(values: any) => setSelectedDepots(values)}
          onChange={handleDropdownChange}
          placeholder="Select version number"
          // multi
          color="#8D8B8F"
          values={[]}
          className="select_dropdown"
          searchable
          searchBy="version"
          required
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .drop-down {
    width: 100%;
  }
  .select_dropdown {
    border: 2px solid #ccc;
  }
  .select_dropdown:hover {
    border: 2px solid #ccc;
  }
`;

export default MinimumDropdown;
