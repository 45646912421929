import { useAppContext } from "context/AppContext";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import toastr from "toastr";
import { ItemType } from "../AppDistribution";
import ArrowLeft from "../../../../assets/svg/arrow-left.svg";
import MinimumDropdown from "./dropdowns/MinimumDropdown";
import AppVersionService from "services/AppVersionService";

type DowngradeFormProps = {
  handleSecondModalClose: () => void;
  item?: ItemType;
  setVersionView: Dispatch<SetStateAction<string>>;
};

const DowngradeForm: React.FC<DowngradeFormProps> = ({
  item,
  setVersionView,
}) => {
  const [appVersion, setAppVersion] = useState([]);
  const [releaseNote, setReleaseNote] = useState("");
  const [versionDetails, setVersionDetails] = useState<[]>([]);
  const [status, setStatus] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { dispatch } = useAppContext();

  const handleKeyPress = (currentValue: any) => (e: any) => {
    const char = e.key;
    if (!/[0-9.]/.test(char) && char !== "Backspace") {
      e.preventDefault();
      return;
    }

    if (char === "." && (currentValue.endsWith(".") || currentValue === "")) {
      e.preventDefault();
      return;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { version } = appVersion[0];

    const formData = {
      version,
      status,
      note: releaseNote,
    };

    try {
      dispatch({ type: "action-loading" });
      setIsLoading(true);

      const { data } = await AppVersionService.updateAppVersionStatus(formData);

      setIsLoading(false);
      setVersionView("successDowngrade");
    } catch (error: any) {
      setIsLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toastr.error(message);
    } finally {
      dispatch({ type: "action-loaded" });
    }
  };

  const handlebackArrow = () => {
    setVersionView("select");
  };

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: { data },
        } = await AppVersionService.dropdownGetAppVerisons();
        const tempData = data.filter((item: any) => item.version);

        setVersionDetails(tempData);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    let statusValidator =
      status === "DEPRECIATE" || status === "EXCLUDE" ? false : true;
    if (appVersion.length > 0 && releaseNote !== "" && !statusValidator) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [appVersion, releaseNote, status]);

  return (
    <Form>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="header">
          <img src={ArrowLeft} alt="" onClick={handlebackArrow} />
          <h2>Manage Version</h2>
        </div>

        <div className="form-control">
          <div className="int-single-dropdown">
            <label>App version</label>
            <MinimumDropdown
              versionDetails={versionDetails}
              setAppVersion={setAppVersion}
            />
          </div>
          {/* <div className="int-group">
            <div className="datee">
              <label>Start date</label>
              <input
                type="date"
                required
                name="startDate"
                value={start_date}
                onChange={(e) => setStart_Date(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="datee">
              <label>End date</label>
              <input
                type="date"
                name="endDate"
                value={end_date}
                onChange={(e) => setEnd_Date(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div> */}
          <div className="int-single">
            <label>Exclusion Reason</label>
            <select
              value={status}
              required
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value=" disabled selected  hidden">
                Select exclusion reason
              </option>
              <option value="DEPRECIATE">End of support</option>
              <option value="EXCLUDE">Buggy verison </option>
            </select>
          </div>
          <div className="int-single">
            <label>Release note</label>
            <textarea
              required
              placeholder="Enter release note"
              value={releaseNote}
              name="releaseNote"
              onChange={(e) => setReleaseNote(e.target.value)}
            ></textarea>
          </div>
          <div className="btn-container">
            <button disabled={isDisabled || isLoading ? true : false}>
              Exclude
            </button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  .header img {
    width: 15px;
    cursor: pointer;
  }
  .header h2 {
    text-align: center;
    flex: 1;
    color: #022f1a;
    font-weight: 500;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 400px;
    overflow-y: auto;
    padding: 0 15px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-single {
    position: relative;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    outline: none;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 120px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 70%;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #ccd5d1;
    cursor: auto;
    color: #777;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    // z-index: 102;
    opacity: 0;
    cursor: pointer;
  }
  .int-single label,
  .int-single-dropdown label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .lrg {
    height: 100px !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
    font-weight: normal;
  }
  .img__container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    background: #fff;
    height: 100%;
    border-bottom: 2px dashed #d4d4d4;
  }
  .imgContainer {
    position: position;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    padding: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 20px;
  }
  .error_message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #d22730 !important;
    font-size: 0.9rem;
  }
  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
    cursor: auto;
  }
  .datee select {
    width: 100%;
  }
`;

export default DowngradeForm;
