import { Dispatch, SetStateAction } from "react";
import Img1 from "../../../../assets/images/auth/success-tick.gif";
import styled from "styled-components";

type SuccessMessageProps = {
  handleSecondModalClose: () => void;
  text: string;
};

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  handleSecondModalClose,
  text,
}) => {
  return (
    <Container>
      <img src={Img1} alt="" />
      <p>{text}</p>
      <div className="btn-container">
        <button onClick={handleSecondModalClose}>Close</button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  margin-bottom: 20px;

  img {
    width: 150px;
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #022f1a;
    width: 70%;
    color: #fff;
    padding: 10px 55px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }

  }
`;

export default SuccessMessage;
